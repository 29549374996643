@import "../variables.scss";

.covid-banner {
  .MuiButton-label {
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  background: $grad-blue-white-bg;
  height: 100%;
  h2 {
    color: aliceblue;
    display: flex;
    margin-left: 1rem;
    font-size: 1rem;
    margin: 0;
    padding-left: 1rem;
    @media (max-width: 767px) {
      font-size: 0.7rem;
      margin-left: 0.5rem;
    }
  }
}
