body {
  margin: 0;
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 330px; */
}
h1, h2, h3 {
  font-family: 'Rufina', serif;
  font-weight: lighter;
}

a {
  text-decoration: none;
}

blockquote {
  border-left: 2px solid #232323;
  padding-left: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  /* max-width: 100%; */
}
