$primary: #002b5c;
$primary-rgb: 0, 43, 92;
$secondary: #9fcbed;
$custom-red: #5c2300;
$secondary-rgb: 159, 203, 237;
$grad-blue-white-bg: linear-gradient(to left, white, $primary 30%);
$grad-blue-dark-bg: linear-gradient(to left, $primary, rgba($secondary, 0.25) 150%);

@function grad-blue-white($direction: to left, $alpha: 1) {
    $bg: linear-gradient($direction, white, rgba($secondary, $alpha) 90%);
    @return $bg;
}

@function grad-blue-dark($direction: to left, $alpha: 1){
    $bg: linear-gradient($direction, darken($primary, 10%), rgba($primary, $alpha) 90%);
    @return $bg;
}
